import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { LinkedList } from '/src/components/common'

const LinkedListItem = ({ title, content }) => {
  return (
    <>
      <h5 className="mb-3">{title}</h5>
      <p>{content}</p>
    </>
  )
}

const PartnersPage = () => {
  return (
    <Layout hero={<HeroImage title="Partners" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <div className="mb-5">
            <Link className="back-to-link" to='/community/about-us'>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to about us
            </Link>
          </div>
          <Row className="d-flex align-items-center mt-5 mb-5">
            <Col sm={12}>
              <LinkedList items={[
                {
                  content: <LinkedListItem 
                    title="Metalcorp"
                    content="Metalcorp is an Australian steel distribution business that is into everything in steel. Our extensive product range includes livestock handling equipment, building materials, structural and tubular steel, fencing, roofing, aluminium, MAXI-TUBE® and more. We pride ourselves on delivering the highest levels of customer service and quality products." />,
                  path: 'https://metalcorpsteel.com.au',
                  external: true
                },
                {
                  content: <LinkedListItem 
                    title="Bluescope"
                    content="BlueScope is a global leader in premium branded coated and painted steel products - the third largest manufacturer of painted and coated steel products globally; we have great strengths in engineered steel buildings in key markets, and are number one in building and construction markets." />,
                  path: 'https://bluescope.com',
                  external: true
                }
              ]} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default PartnersPage

export const Head = () => <title>Partners | Orrcon Steel</title>
